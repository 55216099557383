import React, { Component } from "react"

import Img from 'gatsby-image'
import {StaticQuery, graphql, Link } from "gatsby"
import gat from '../images/gatsby-icon.png'
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import PropTypes from "prop-types"
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Icons from 'uikit/dist/js/uikit-icons'
import UofM_Med from '../images/uofmMed.png'

import donate from '../images/Donate.jpg'
import getinvolved from '../images/20180724_132201.jpg'
import partner from '../images/Partners.jpg'
import handup from '../images/20180724_132201.jpg'
import homepage from "../images/homepage.jpg"
import enrich from "../images/20160809_121700.jpg"
import volunteer from '../images/volunteer.jpg'
import foodgath from '../images/FG@2x.png'
import comcast from '../images/Comcast@2x.png'
import fli from '../images/FLI@2x.png'
import h4h_logo from '../images/H4H-logo@2x.png'
import lf_logo from '../images/LF-logo@2x.png'
import sheriff from '../images/Shreriff@2x.png'
import UofM_health from '../images/UofM_health@2x.png'
import washtenaw_lit from '../images/Washtenawliteracy@2x.png'
import wcparc from '../images/WCPARC_COLOR_logo@2x.png'
import ycs from '../images/YCS@2x.png'
{/*let Uikit = {} // you can stub it to whatever you need to to make it run in node.
let uiExt = {}
let Icon = {}
if (typeof document !== 'undefined') {
  Uikit = require('uikit').default
  uiExt = require('react-uikit3')
   Icon = uiExt.Icon

}
*/}
//uiExt.Slideshow('#mySlide')
//uiExt.use({Slideshow})
//import {Slideshow, Cover} from uiExt;

//UIkit.slideshow(element, options);
 
// const Slideshow = require('react-slidez');
      // let info = data.allWordpressPost.edges.toArray({node})
const IndexPage = ({oneNode}, {secNode}) => (

<StaticQuery
    query={graphql`

       query postQuery{
        allWordpressPost{
            edges{
                node{
                    id
                    title
                    excerpt
                    slug
                    date(formatString: "MMMM DD, YYYY")
                    featured_media {
                         localFile{
                            childImageSharp{
                                resolutions(width:800, height: 300){
                                    src
                                    width
                                    height
                                }
                            }
                        }
                     }
                }
            }
        }


    imageOne: file(relativePath: { eq: "homepage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  

    }

    


    
    `}




    render = {data => (

      <Layout>
    <SEO title="Home" keywords={[`cflc`, `community`, `organization`]} />
  
<div class="uk-cover-container" style={{maxHeight: '500px'}}>
<Img fluid={data.imageOne.childImageSharp.fluid} alt="Homepage image" uk-cover/>


        <div class="uk-position-cover" uk-slideshow-parallax="opacity: 0,0,0.2; backgroundColor: #000,#000"></div>
            <div class="uk-position-top-left uk-position-medium uk-text-center uk-padding" style={{backgroundColor: 'white', color: 'orange', background: 'rgba(204, 204, 204, 0.7)' }}>
                <div data-uk-slideshow-parallax="scale: 1,1,0.8"  class="uk-padding-small">
                    <h1 data-uk-slideshow-parallax="x: 200,0,0" style={{ color: '#2D4B65'}}>CFLC</h1>
                    <p data-uk-slideshow-parallax="x: 400,0,0;" style={{ color: '#2D4B65'}}>Helping the community</p>
                </div>
                </div>
</div>

{/*<div   class="uk-cover-container "  >
  <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" data-uk-slideshow>


 
    <ul className="data-uk-slideshow-items "  >
    <li>

        <img  src={homepage}  data-uk-cover/>
        <div class="uk-position-cover" uk-slideshow-parallax="opacity: 0,0,0.2; backgroundColor: #000,#000"></div>
            <div class="uk-position-top-left uk-position-medium uk-text-center uk-padding" style={{backgroundColor: 'white', color: 'orange', background: 'rgba(204, 204, 204, 0.7)' }}>
                <div data-uk-slideshow-parallax="scale: 1,1,0.8"  class="uk-padding-small">
                    <h1 data-uk-slideshow-parallax="x: 200,0,0" style={{ color: '#2D4B65'}}>CFLC</h1>
                    <p data-uk-slideshow-parallax="x: 400,0,0;" style={{ color: '#2D4B65'}}>Helping the community</p>
                </div>
                </div>
      </li>
      <li>
        <img src={enrich} data-uk-cover/>
        <div class="uk-position-cover" uk-slideshow-parallax="opacity: 0,0,0.2; backgroundColor: #000,#000"></div>
            <div class="uk-position-center uk-position-medium uk-text-center uk-padding" style={{backgroundColor: 'white', color: 'orange', background: 'rgba(204, 204, 204, 0.7)' }}>
                <div uk-slideshow-parallax="scale: 1,1,0.8"  class="uk-padding-small">
                    <h1 uk-slideshow-parallax="x: 200,0,0" style={{ color: '#2D4B65'}}>CFLC</h1>
                    <p uk-slideshow-parallax="x: 400,0,0;" style={{ color: '#2D4B65'}}>Helping the community</p>
                </div>
                </div>
      </li>
      <li>
        <img  src={handup} data-uk-cover/>
        <div class="uk-position-cover" uk-slideshow-parallax="opacity: 0,0,0.2; backgroundColor: #000,#000"></div>
            <div class="uk-position-top-left uk-position-medium uk-text-center uk-padding" style={{backgroundColor: 'white', color: 'orange', background: 'rgba(204, 204, 204, 0.7)' }}>
                <div uk-slideshow-parallax="scale: 1,1,0.8"  class="uk-padding-small">
                    <h1 uk-slideshow-parallax="x: 200,0,0" style={{ color: '#2D4B65'}}>CFLC</h1>
                    <p uk-slideshow-parallax="x: 400,0,0;" style={{ color: '#2D4B65'}}>Helping the community</p>
                </div>
                </div>
      </li>
    </ul>

     <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-previous data-uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-next data-uk-slideshow-item="next"></a>
    </div>

    </div>

</div>

*/}


    <div uk-grid  class="uk-section-primary uk-section-small">
    <div style={{ height: '40%'}} class="uk-container uk-text-center">
        <h1>What We Do</h1>
        <p >CFLC is a neighborhood-based, non-profit organization that serves as a hub for the Sugarbrook Neighborhood 
and the greater Ypsilanti area. Our mission is to serve individuals, youth and their families with programming focused on 
social, educational, physical and economic well-being.  
       </p>
    </div>
   

</div>

<div >

</div>



<div uk-grid class="uk-section-small">
  <h1 class="uk-text-center">Get involved</h1>
  
  <div class="uk-flex uk-child-width-1-3@m uk-container flexdirection " uk-grid style={{ height: '50vh', padding: '10px 5px 50px 5px'}} >
     {/* <div class="uk-card uk-card-default uk-card-body uk-card-hover"><img src={volunteer} alt=""class="ran2 " /><h4 class="uk-h4 uk-overlay uk-position-center ">Donate</h4></div>*/}
  
 <div class="uk-card uk-card-default uk-card-body uk-margin-left  uk-card-hover cardLayout" >

            <div class="uk-overlay uk-overlay-primary uk-position-cover ">
                <h4 class="uk-h4 uk-text-center uk-position-center uk-text-uppercase">Donate</h4>
                <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
 <input type="hidden" name="cmd" value="_s-xclick" />
<input type="hidden" name="hosted_button_id" value="CPJM87QS45MXS" />
    <a href="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gifr" >
      <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
    
    </a>
    </form>
            </div>
           
      </div>
     <div class="uk-card uk-card-default uk-card-body uk-margin-left  uk-card-hover cardLayout" >
    <a href="/getinvolved">
            <div class="uk-overlay uk-overlay-primary uk-position-cover">
                <h4 class="uk-h4 uk-text-center uk-position-center uk-text-uppercase">Volunteer</h4>
            </div>
           </a>
        </div>
        
    <div class="uk-card uk-card-default uk-card-body uk-margin-left uk-card-hover cardLayout" >
    <a href="/programs">
            <div class="uk-overlay uk-overlay-primary uk-position-cover">
                <h4 class="uk-h4 uk-text-center uk-position-center uk-text-uppercase">Partner</h4>
            </div>
           </a>
        </div>

  </div>
 
<div class="uk-flex uk-child-width-1-2@m  uk-container uk-margin-bottom uk-flex-row flexdirection "  style={{ height: '20%'}} >
      <div class="uk-section uk-section-primary uk-text-center uk-padding uk-margin-right" >
        <h2> Subscribe to Stay Updated</h2>
        <p>Subscribe to our monthly newsletter and stay up to date with all news and events.</p>
        <form >
          <fieldset class="uk-fieldset " >
            <input class="uk-input homeForm uk-margin-bottom" type="text" placeholder="Name"/>
            <input class="uk-input uk-margin-bottom"  type="text" placeholder="Email Address" name="email" type="email"/>
            <button class="uk-button uk-button-secondary uk-rounded" style={{backgroundColor: '#E5901A', color: 'white'}}> Subscribe</button> 

            </fieldset>
        </form>


      </div>
      <div class="eventsbox uk-visible@m">  
        <h1>Upcoming Events</h1>

<iframe src="https://calendar.google.com/calendar/b/1/embed?showTitle=0&amp;showNav=0&amp;showDate=0&amp;showTabs=0&amp;mode=AGENDA&amp;height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;src=5f5pd7mabsqc8ocsp2n7hdmp74%40group.calendar.google.com&amp;color=%2329527A&amp;ctz=America%2FDetroit" style={{border:0}} width="600" height="300" frameborder="0" scrolling="no"></iframe>
        



      </div>
</div>

<div  style={{ height: '30%'}} class="uk-section-primary uk-section-small">
  <h1 class="uk-text-center">News</h1>
  <div class="uk-grid-match " uk-grid >
    <div style={{ height:'30%'}}  class="uk-container uk-flex uk-flex-center newsSec" uk-grid>
{
          
          <div key={data.allWordpressPost.edges[0].node.slug} className={"post"} style={{ marginBottom: 50 }} >
          <div class=" uk-padding-small ">
                        <Link to={'post/' + data.allWordpressPost.edges[0].node.slug}>
                            <h4 style={{color: '#E5901A' }}>{data.allWordpressPost.edges[0].node.title}</h4>

                        </Link>
                        <img class="uk-width-1-3@m uk-margin-right" src={data.allWordpressPost.edges[0].node.featured_media.localFile.childImageSharp.resolutions.src} />

                        <p class="uk-width-1-2@m  uk-padding-small uk-float-right uk-text-small">
                        <p className={"post-content"} class="uk-text-small" dangerouslySetInnerHTML={{__html: data.allWordpressPost.edges[0].node.excerpt}} />
                        <div>
                        {data.allWordpressPost.edges[0].node.date}
                        </div>
                         <button class="uk-button uk-border-rounded" > Read More </button>
                        </p>
                        

        
        </div>
                    </div>
          

       }
       
    </div>
    <div class="uk-container uk-flex uk-flex-center newsSec" uk-grid>
    
        {
          
          <div key={data.allWordpressPost.edges[1].node.slug} className={"post"} style={{ marginBottom: 50 }}>
                        <Link to={'post/' + data.allWordpressPost.edges[1].node.slug}>
                            <h4 style={{color: '#E5901A' }}>{data.allWordpressPost.edges[1].node.title}</h4>

                        </Link>
                        <img class="uk-width-1-3@m uk-margin-right uk-float-right" src={data.allWordpressPost.edges[1].node.featured_media.localFile.childImageSharp.resolutions.src} />

                        <div class="uk-width-1-2@m  uk-padding-small">
                        <div className={"post-content"} class="uk-text-small" dangerouslySetInnerHTML={{__html: data.allWordpressPost.edges[1].node.excerpt}} />
                       
                        {data.allWordpressPost.edges[1].node.date}
                        <div>
                                                  <button class="uk-button uk-border-rounded" > Read More </button>

                      </div>
                        </div>
        
                    </div>
          

       }
      
    </div>


    
  </div>
 



</div>
<div  style={{ height: '50%'}} class="uk-section-small">
  <h1 class="uk-text-center">Our Partners</h1>
  <div class="uk-child-width-1-5 uk-grid-medium uk-container " style={{marginLeft:'5%'}}uk-grid>
    <img   src={foodgath}/>
    <img   src={fli}/>
    <img   src={comcast}/>
    <img   src={washtenaw_lit}/>
    <img   src={UofM_Med}/>

    <img   src={sheriff}/>
    <img  src={ycs}/>
    <img  src={lf_logo}/>
    <img  src={wcparc}/>
    <img  src={h4h_logo}/>



  </div>
 
</div>

</div>
  </Layout>





      )}
/>


)

   

  

       


IndexPage.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array,
}

export default IndexPage

